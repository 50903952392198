import React from "react";
import InfoSection from "./InfoSection";
import { IoMdDocument } from "react-icons/io";
import "../App.css";
import Typing from "react-typing-animation";
import WorkShowcase from "./WorkShowcase";

//Static resources
const profile = require("../images/profile.jpg");
const cv = require("../documents/MikołajZyzańskiCV.pdf");
const Hero = () => {
  return (
    <div>
      <div style={styles.container}>
        <div style={styles.imgContainer}>
          <img
            style={styles.image}
            src={profile}
            alt="A candid profile of Mikołaj Zyzański"
          />
          <h2 style={styles.title}>Mikołaj Zyzański</h2>
        </div>
        <div style={styles.textContainer}>
          <Typing>
            <h1 style={styles.title}>Dedicated & Passionate.</h1>
          </Typing>
          <p style={styles.desc}>
            Hi! I'm Miko, a passionate Web and Native app developer who
            specialises in using React and React Native.
          </p>
        </div>
      </div>
      <div style={styles.infoContainer}>
        <h2 style={styles.subTitle}>Basic Information</h2>
        <p style={styles.infoText}>
          <b>Location:</b> Warsaw, Poland
        </p>
        <p style={styles.infoText}>
          <b>Occupation:</b> Junior React Native Developer at Iterators & 3rd
          Year Computer Science at PJATK
        </p>
        <p style={styles.infoText}>
          <b>Languages:</b> English, Polish, conversational French
        </p>
        <p style={styles.infoText}>
          <b>
            <a href={cv} download>
              <IoMdDocument /> Download CV
            </a>
          </b>
        </p>
      </div>
      <InfoSection
        title={"International Background"}
        text={
          "Having lived in 7 different countries (Europe, Asia, Middle East) I am comfortable in international teams and well versed in cultural differences."
        }
      />
      <div>
        <h1 style={styles.title}>Work Experience</h1>
        <WorkShowcase
          title="Iterators"
          icon={require("../images/iteratorsIcon1.jpg")}
          desc="As a Junior React Native Developer built frontend code for multiple projects, supported clients with architectural knowledge, and liaised with product, design and development teams to ensure proper quality of deliverables."
          date="October 2022 - Ongoing"
          role="Junior React Native Developer"
          techs="React Native, React Native Web, MobX"
          links={[["Iterators", "https://www.iteratorshq.com/"]]}
        />
        <hr />
        <WorkShowcase
          title="Cleeng"
          icon={require("../images/cleengIcon1.jpg")}
          desc="As a QA intern at Cleeng I spearheaded the development of an internal testing app, written in React Native, used to test in-app purchases on Android and iOS."
          date="June 2021 - October 2021"
          role="QA Intern"
          techs="React Native, Jest"
          links={[["Cleeng", "https://cleeng.com/"]]}
        />
      </div>
      <hr />
    </div>
  );
};

// #98ffcc (Mint)

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#ffffff",
    alignSelf: "center",
    margin: "20px",
    minHeight: "100vh",
  },
  infoContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column",
    flexWrap: "wrap",
    backgroundColor: "#ffffff",
    alignSelf: "center",
    margin: "20px",
  },
  imgContainer: {
    alignSelf: "center",
  },
  infoText: {
    textAlign: "center",
  },
  textContainer: {
    alignSelf: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    maxWidth: "50vw",
  },
  title: {
    color: "#000000",
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "#98ffcc",
  },
  subTitle: {
    color: "#000000",
    textAlign: "center",
    textDecoration: "underline",
    textDecorationColor: "#98ffcc",
    margin: "1px",
  },
  desc: {
    textAlign: "center",
    fontSize: "18px",
  },
  image: {
    maxHeight: "40vh",
    maxWidth: "40vh",
    borderRadius: "100%",
    alignSelf: "center",
    backgroundColor: "#000000",
    padding: "5px",
  },
};

export default Hero;
